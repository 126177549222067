import React, { useRef, useCallback, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useEditorContext } from '../hooks/useEditorContext';
import { EditorToolbar } from './EditorToolbar';
import { AlertTriangle } from 'lucide-react';

interface EditorProps {
  value: string;
  onChange: (value: string) => void;
  initialContent: string;
  onScroll?: (scrollInfo: ScrollInfo) => void;
  syncScroll: boolean;
  setSyncScroll: (value: boolean) => void;
}

interface ScrollInfo {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

// Content limit constants
const CONTENT_LIMITS = {
  MAX_SIZE_BYTES: 5 * 1024 * 1024,      // 5MB in bytes
  MAX_CHARS: 100_000,                    // Maximum character limit
  WARNING_CHARS: 80_000,                 // Warning threshold
  MIN_CONTENT_LENGTH: 1,
} as const;

// Helper functions for content limits and formatting
const getContentSize = (content: string): number => {
  return new Blob([content]).size;
};

const formatNumber = (num: number): string => {
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

const getContentStatus = (content: string) => {
  const charCount = content.length;
  const sizeBytes = getContentSize(content);
  
  return {
    charCount,
    sizeBytes,
    isApproachingLimit: charCount >= CONTENT_LIMITS.WARNING_CHARS,
    isAtLimit: charCount >= CONTENT_LIMITS.MAX_CHARS || sizeBytes >= CONTENT_LIMITS.MAX_SIZE_BYTES,
    percentUsed: (charCount / CONTENT_LIMITS.MAX_CHARS) * 100,
    formattedCount: formatNumber(charCount),
    formattedMax: formatNumber(CONTENT_LIMITS.MAX_CHARS)
  };
};

export const Editor: React.FC<EditorProps> = ({
  value,
  onChange,
  initialContent,
  onScroll,
  syncScroll,
  setSyncScroll
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const lastSavedRef = useRef<string>(value);
  const [contentStatus, setContentStatus] = useState<ReturnType<typeof getContentStatus>>(getContentStatus(value));
  const warningShownRef = useRef<boolean>(false);
  const [copyInProgress, setCopyInProgress] = useState(false);
  const { state: { isMaximized }, toggleMaximize } = useEditorContext();

  // Content validation before change
  const validateContentChange = useCallback((newContent: string): boolean => {
    const status = getContentStatus(newContent);
    
    if (status.isAtLimit) {
      toast.error('Content limit reached. Please reduce content before continuing.');
      return false;
    }
    return true;
  }, []);

  // Content status update
  useEffect(() => {
    const status = getContentStatus(value);
    setContentStatus(status);

    if (status.isApproachingLimit && !warningShownRef.current) {
      toast.custom(
        <div className="flex items-center gap-2">
          <AlertTriangle className="h-5 w-5 text-yellow-500" />
          <div>
            <p className="font-medium">Approaching content limit</p>
            <p className="text-sm text-slate-200">
              {`${status.formattedCount} of ${status.formattedMax} characters used`}
            </p>
          </div>
        </div>,
        {
          duration: 4000,
          id: 'content-limit-warning',
        }
      );
      warningShownRef.current = true;
    }

    if (!status.isApproachingLimit) {
      warningShownRef.current = false;
    }
  }, [value]);

  // Handle content change with limits
  const handleContentChange = useCallback((newContent: string) => {
    if (!validateContentChange(newContent)) return;
    
    onChange(newContent);
    try {
      localStorage.setItem('markdown-content', newContent);
      lastSavedRef.current = newContent;
    } catch (error) {
      console.error('Failed to auto-save:', error);
      toast.error('Failed to auto-save content');
    }
  }, [onChange, validateContentChange]);

  // Action handlers
  const handleClear = useCallback(() => {
    if (value.length <= CONTENT_LIMITS.MIN_CONTENT_LENGTH) {
      toast.error('Editor is already empty');
      return;
    }

    if (window.confirm('Are you sure you want to clear all content? This action cannot be undone.')) {
      onChange('');
      lastSavedRef.current = '';
      localStorage.removeItem('markdown-content');
      toast.success('Content cleared successfully');
    }
  }, [value, onChange]);

  const handleReset = useCallback(() => {
    if (value === initialContent) {
      toast.error('Content is already at default state');
      return;
    }

    if (!validateContentChange(initialContent)) return;

    if (window.confirm('Are you sure you want to reset to the default content? This action cannot be undone.')) {
      onChange(initialContent);
      lastSavedRef.current = initialContent;
      localStorage.setItem('markdown-content', initialContent);
      toast.success('Content reset to default');
    }
  }, [value, initialContent, onChange, validateContentChange]);

  const handleCopy = useCallback(async () => {
    if (value.length <= CONTENT_LIMITS.MIN_CONTENT_LENGTH) {
      toast.error('No content to copy');
      return;
    }

    if (copyInProgress) return;

    try {
      setCopyInProgress(true);
      await navigator.clipboard.writeText(value);
      toast.success('Copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy content');
    } finally {
      setTimeout(() => setCopyInProgress(false), 1000);
    }
  }, [value, copyInProgress]);

  const handleDownload = useCallback(() => {
    if (value.length <= CONTENT_LIMITS.MIN_CONTENT_LENGTH) {
      toast.error('No content to download');
      return;
    }

    try {
      const blob = new Blob([value], { type: 'text/markdown' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      a.download = `document-${timestamp}.md`;
      a.click();
      URL.revokeObjectURL(url);
      toast.success('Document downloaded successfully');
    } catch (error) {
      console.error('Failed to download:', error);
      toast.error('Failed to download document');
    }
  }, [value]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLTextAreaElement>) => {
    if (!syncScroll || !onScroll) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    onScroll({ scrollTop, scrollHeight, clientHeight });
  }, [syncScroll, onScroll]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      toggleMaximize();
    }
  }, [toggleMaximize]);

  // Progress bar color based on usage
  const getProgressColor = useCallback((percentUsed: number): string => {
    if (percentUsed >= 95) return 'bg-red-500';
    if (percentUsed >= 80) return 'bg-yellow-500';
    return 'bg-green-500';
  }, []);

  return (
    <div 
      className={`flex flex-col ${
        isMaximized 
          ? 'fixed inset-0 z-50 bg-slate-900 p-4' 
          : 'h-[40vh] md:h-full bg-slate-900'
      }`}
      role="region"
      aria-label="Markdown Editor"
    >
      {/* Toolbar */}
      <EditorToolbar
        syncScroll={syncScroll}
        onSyncScrollChange={setSyncScroll}
        onClear={handleClear}
        onReset={handleReset}
        onCopy={handleCopy}
        onDownload={handleDownload}
        isFullscreen={isMaximized}
        onFullscreenToggle={toggleMaximize}
        copyInProgress={copyInProgress}
        hasContent={value.length > CONTENT_LIMITS.MIN_CONTENT_LENGTH}
      />

      {/* Content Limit Progress Bar */}
      <div className="relative h-0.5 bg-slate-700">
        <div 
          className={`h-full ${getProgressColor(contentStatus.percentUsed)}`}
          style={{ width: `${Math.min(contentStatus.percentUsed, 100)}%` }}
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={Math.round(contentStatus.percentUsed)}
          aria-label="Content limit progress"
        />
      </div>

      {/* Editor Content */}
      <div className="flex-1 min-h-0 relative">
        <textarea
          ref={textareaRef}
          value={value}
          onChange={(e) => handleContentChange(e.target.value)}
          onScroll={handleScroll}
          onKeyDown={handleKeyDown}
          className="absolute inset-0 w-full h-full p-4 bg-slate-900 text-slate-200 resize-none focus:outline-none font-mono"
          placeholder="Type your markdown here..."
          aria-label="Markdown editor content"
          spellCheck="true"
          maxLength={CONTENT_LIMITS.MAX_CHARS}
        />
      </div>

      {/* Stats and Save Status Bar */}
      <div className="bg-gray-700 p-2 text-white text-sm flex justify-between items-center">
        <div>
          {value.trim().split(/\s+/).filter(word => word.length > 0).length.toLocaleString()} words | {value.length.toLocaleString()} characters | {value.trim() ? value.split('\n').length.toLocaleString() : '0'} lines
        </div>
        {value !== lastSavedRef.current && (
          <div className="text-yellow-400 text-xs">Unsaved changes</div>
        )}
      </div>
    </div>
  );
};

export default Editor;