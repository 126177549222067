import ReactGA from 'react-ga4';

// Declare window interface for dataLayer
declare global {
  interface Window {
    dataLayer?: Array<Record<string, unknown>>;
  }
}

const GA_MEASUREMENT_ID = 'G-39MSKDTQ5J';
const GTM_ID = 'GTM-WWW7J4XT';
const ENABLE_ANALYTICS = true;

// Initialize Google Tag Manager
export const initGTM = () => {
  if (!ENABLE_ANALYTICS || !GTM_ID) return;

  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
  `;
  document.head.appendChild(script);

  // Add GTM noscript iframe
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstChild);
};

// Initialize Google Analytics
export const initGA = () => {
  if (!ENABLE_ANALYTICS || !GA_MEASUREMENT_ID) return;

  ReactGA.initialize(GA_MEASUREMENT_ID, {
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });
};

// Log page views
export const logPageView = (pathname: string, search: string = '') => {
  if (!ENABLE_ANALYTICS) return;

  ReactGA.send({ 
    hitType: 'pageview',
    page: pathname + search
  });

  // Push to dataLayer for GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: pathname,
        title: document.title,
        search: search,
        url: window.location.href
      }
    });
  }
};

// Enhanced event logging
export const logEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction: boolean = false
) => {
  if (!ENABLE_ANALYTICS) return;

  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction
  });

  // Push to dataLayer for GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'custom_event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction
    });
  }
};

// Convenience functions for specific events
export const trackEditorEvent = (action: string, label?: string) => {
  logEvent('Editor', action, label);
};

export const trackToolUsage = (toolName: string, action: string) => {
  logEvent('Tools', action, toolName);
};

export const trackFeature = (featureName: string, action: string) => {
  logEvent('Features', action, featureName);
};

export const trackError = (errorMessage: string, errorCode?: string) => {
  logEvent('Error', errorCode || 'Error Occurred', errorMessage, undefined, true);
};

export const trackPerformance = (metric: string, value: number) => {
  logEvent('Performance', metric, undefined, value, true);
};