// src/hooks/useEditorContext.ts
import { useContext } from 'react';
import { EditorContext } from '../context/EditorContext';
import type { EditorContextValue } from '../context/types';

export function useEditorContext(): EditorContextValue {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditorContext must be used within an EditorProvider');
  }
  return context;
}