// src/context/EditorProvider.tsx
import { ReactNode, useReducer, useCallback } from 'react';
import { defaultContent } from '../constants/defaultContent';
import { EditorContext } from './EditorContext';
import type { EditorState, EditorAction, ScrollPosition } from './types';

const initialState: EditorState = {
  content: localStorage.getItem('markdown-content') || defaultContent,
  syncScroll: false,
  editorScrollPosition: undefined,
  isDirty: false,
  lastSaved: null,
  isMaximized: false
};

function editorReducer(state: EditorState, action: EditorAction): EditorState {
  switch (action.type) {
    case 'SET_CONTENT':
      return { ...state, content: action.payload, isDirty: true };
    case 'TOGGLE_SYNC_SCROLL':
      return { ...state, syncScroll: !state.syncScroll };
    case 'SET_EDITOR_SCROLL':
      return { ...state, editorScrollPosition: action.payload };
    case 'MARK_SAVED':
      return { ...state, isDirty: false, lastSaved: new Date() };
    case 'RESET_CONTENT':
      return { ...state, content: defaultContent, isDirty: true };
    case 'TOGGLE_MAXIMIZE':
      return { ...state, isMaximized: !state.isMaximized };
    default:
      return state;
  }
}

export function EditorProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(editorReducer, initialState);

  const setContent = useCallback((content: string) => {
    dispatch({ type: 'SET_CONTENT', payload: content });
    localStorage.setItem('markdown-content', content);
  }, []);

  const toggleSyncScroll = useCallback(() => dispatch({ type: 'TOGGLE_SYNC_SCROLL' }), []);
  const setEditorScroll = useCallback((position: ScrollPosition) => dispatch({ type: 'SET_EDITOR_SCROLL', payload: position }), []);
  const markSaved = useCallback(() => dispatch({ type: 'MARK_SAVED' }), []);
  const resetContent = useCallback(() => dispatch({ type: 'RESET_CONTENT' }), []);
  const toggleMaximize = useCallback(() => dispatch({ type: 'TOGGLE_MAXIMIZE' }), []);

  const value = { 
    state, 
    setContent, 
    toggleSyncScroll, 
    setEditorScroll, 
    markSaved, 
    resetContent, 
    toggleMaximize 
  };

  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}