// src/utils/highlight.ts

import Prism from 'prismjs';

// Import core languages
import 'prismjs/components/prism-markup';  // HTML
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';

// Import plugins
import 'prismjs/plugins/toolbar/prism-toolbar'; // Import Toolbar plugin first
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/show-language/prism-show-language'; // Import Show Languages plugin after Toolbar

// Import themes
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

// Utility function for highlighting code
export const highlightCode = (code: string, language: string = ''): string => {
  try {
    // If language is specified and exists in Prism
    if (language && Prism.languages[language]) {
      const result = Prism.highlight(code, Prism.languages[language], language);
      return typeof result === 'string' ? result : code;
    }
    
    // Try auto-detection
    for (const lang of Object.keys(Prism.languages)) {
      try {
        if (Prism.languages[lang]) {
          const result = Prism.highlight(code, Prism.languages[lang], lang);
          if (result !== code && typeof result === 'string') {
            return result;
          }
        }
      } catch {
        continue;
      }
    }

    // Fallback to plain text by escaping HTML special characters
    return code
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');

  } catch (error) {
    console.error('Error highlighting code:', error);
    return code; // Return original code if highlighting fails
  }
};

// Helper function to wrap highlighted code in pre/code tags
export const wrapHighlightedCode = (code: string, language: string = ''): string => {
  const languageClass = language ? `language-${language}` : '';
  return `<pre class="line-numbers ${languageClass}"><code class="${languageClass}">${code}</code></pre>`;
};

export default {
  highlight: highlightCode,
  wrap: wrapHighlightedCode
};