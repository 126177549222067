import { Trash2, RotateCcw, Copy, Download, Maximize2, Minimize2, Loader2 } from 'lucide-react';

interface EditorToolbarProps {
  syncScroll: boolean;
  onSyncScrollChange: (value: boolean) => void;
  onClear: () => void;
  onReset: () => void;
  onCopy: () => void;
  onDownload: () => void;
  isFullscreen: boolean;
  onFullscreenToggle: () => void;
  copyInProgress: boolean;
  hasContent: boolean;
}

export function EditorToolbar({
  syncScroll,
  onSyncScrollChange,
  onClear,
  onReset,
  onCopy,
  onDownload,
  isFullscreen,
  onFullscreenToggle,
  copyInProgress,
  hasContent
}: EditorToolbarProps) {
  return (
    <div className="flex flex-col bg-slate-800 border-b border-slate-700">
      <div className="px-3 py-2 overflow-x-auto">
        <div className="flex items-center justify-between space-x-2 min-w-max">
          {/* Left side - Editor Label */}
          <div className="text-sm font-medium text-slate-400">
            Editor
          </div>

          {/* Right side - Actions including Sync Scroll */}
          <div className="flex items-center space-x-3">
            {/* Sync Scroll */}
            <label className="flex items-center gap-2 text-xs text-slate-400 min-w-max">
              <input
                type="checkbox"
                checked={syncScroll}
                onChange={(e) => onSyncScrollChange(e.target.checked)}
                className="w-4 h-4 rounded border-slate-600 text-indigo-600 focus:ring-indigo-500 bg-slate-700"
              />
              <span>Sync Scroll</span>
            </label>

            {/* Clear Button */}
            <button
              onClick={onClear}
              className="p-1.5 text-slate-400 hover:text-slate-200 hover:bg-slate-700 rounded-md transition-colors disabled:opacity-50"
              title="Clear editor content"
              disabled={!hasContent}
            >
              <Trash2 className="h-4 w-4" />
            </button>

            {/* Reset Button */}
            <button
              onClick={onReset}
              className="p-1.5 text-slate-400 hover:text-slate-200 hover:bg-slate-700 rounded-md transition-colors"
              title="Reset to default content"
            >
              <RotateCcw className="h-4 w-4" />
            </button>

            {/* Copy Button */}
            <button
              onClick={onCopy}
              className={`p-1.5 rounded-md transition-colors ${
                copyInProgress 
                  ? 'bg-slate-700 text-slate-400 cursor-wait' 
                  : 'text-slate-400 hover:text-slate-200 hover:bg-slate-700'
              }`}
              disabled={copyInProgress || !hasContent}
              title="Copy to clipboard"
            >
              {copyInProgress ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </button>

            {/* Download Button */}
            <button
              onClick={onDownload}
              className="p-1.5 text-slate-400 hover:text-slate-200 hover:bg-slate-700 rounded-md transition-colors disabled:opacity-50"
              title="Download as markdown"
              disabled={!hasContent}
            >
              <Download className="h-4 w-4" />
            </button>

            {/* Fullscreen Toggle */}
            <button
              onClick={onFullscreenToggle}
              className={`p-1.5 text-slate-400 hover:text-slate-200 hover:bg-slate-700 rounded-md transition-colors ${
                isFullscreen ? 'bg-slate-700' : ''
              }`}
              title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
            >
              {isFullscreen ? (
                <Minimize2 className="h-4 w-4" />
              ) : (
                <Maximize2 className="h-4 w-4" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}