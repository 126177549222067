import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Editor } from './components/Editor';
import Preview from './components/Preview';
import Header from './components/Header';
import Footer from './components/Footer';
import { Head } from './components/Head';
import { defaultContent } from './constants/defaultContent';
import { EditorProvider } from './context/EditorProvider';
import { useEditorContext } from './hooks/useEditorContext';
import AppRoutes from './routes';
import { initGA, initGTM, logPageView } from './services/analytics';
import type { ScrollPosition } from './context/types';

// Main editor layout component
export const EditorLayout: React.FC = () => {
  const [content, setContent] = useState(() => {
    const savedContent = localStorage.getItem('markdown-content');
    return savedContent || defaultContent;
  });
  const [syncScroll, setSyncScroll] = useState(false);
  const [editorScrollPosition, setEditorScrollPosition] = useState<ScrollPosition>();
  const { state: { isMaximized } } = useEditorContext();

  useEffect(() => {
    if (!content) return;
    try {
      localStorage.setItem('markdown-content', content);
    } catch (error) {
      console.error('Error saving content:', error);
    }
  }, [content]);

  return (
    <div className="fixed inset-0 flex flex-col">
      <Header />
      <main 
        className={`flex-1 overflow-hidden bg-slate-100 ${isMaximized ? 'p-0' : 'p-6'}`}
        role="main"
        aria-label="Markdown editor and preview area"
      >
        <div 
          className={`h-full ${isMaximized ? '' : 'grid grid-cols-1 md:grid-cols-2 gap-2'}`}
          role="application"
          aria-label="Markdown live preview interface"
        >
          <Editor 
            value={content} 
            onChange={setContent} 
            initialContent={defaultContent}
            onScroll={setEditorScrollPosition}
            syncScroll={syncScroll}
            setSyncScroll={setSyncScroll}
          />
          {!isMaximized && (
            <Preview 
              content={content} 
              syncScroll={syncScroll}
              scrollPosition={editorScrollPosition}
            />
          )}
        </div>
      </main>
      <Footer className="p-1" />
    </div>
  );
};

// Analytics wrapper component
const AnalyticsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (import.meta.env.PROD) {
      logPageView(location.pathname, location.search);
    }
  }, [location]);

  return <>{children}</>;
};

const App: React.FC = () => {
  // Initialize analytics once when app starts
  useEffect(() => {
    if (import.meta.env.PROD) {
      initGTM();
      initGA();
    }
  }, []);

  return (
    <HelmetProvider>
      <EditorProvider>
        <Router>
          <AnalyticsWrapper>
            <Head />
            <AppRoutes />
            <Toaster
              position="bottom-right"
              toastOptions={{
                className: 'react-hot-toast',
                success: {
                  iconTheme: {
                    primary: '#10b981',
                    secondary: '#ffffff',
                  },
                },
                error: {
                  iconTheme: {
                    primary: '#ef4444',
                    secondary: '#ffffff',
                  },
                },
                style: {
                  background: '#18181b',
                  color: '#ffffff',
                  padding: '12px',
                  borderRadius: '6px',
                },
              }}
            />
          </AnalyticsWrapper>
        </Router>
      </EditorProvider>
    </HelmetProvider>
  );
};

export default App;