// src/components/Head.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

// Types
interface HeadProps {
  title?: string;
  description?: string;
  keywords?: string;
}

interface PageMetadata {
  title: string;
  description: string;
  keywords: string;
  type?: string;
  structuredData?: object;
}

// Enhanced default metadata targeting primary keywords
const DEFAULT_METADATA: PageMetadata = {
  title: 'Markdown Live Editor - Free Online Preview Tool',
  description: 'Free online markdown live editor with instant preview. Create, edit and test markdown in real-time. Perfect for README files, documentation, and formatted text.',
  keywords: 'markdown live editor, markdown live preview, markdownlivepreview, markdown online preview, live markdown editor, markdown test online, online markdown editor with preview, markdown preview online',
  type: 'website',
  structuredData: {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Markdown Live Editor",
    "description": "Free online markdown live editor with instant preview",
    "applicationCategory": "DeveloperApplication",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "Live Preview",
      "Table Generator",
      "Link Generator",
      "List Generator",
      "HTML Converter",
      "Code Formatting",
      "Syntax Highlighting",
      "Auto-save Feature"
    ]
  }
};

// Enhanced FAQ data with targeted keywords
const FAQ_STRUCTURED_DATA = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How do I preview markdown online?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Simply type or paste your markdown in our editor and see the live preview instantly. Our markdown live editor shows formatted output in real-time."
      }
    },
    {
      "@type": "Question",
      "name": "Can I test markdown formatting online?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, use our markdown live preview tool to test any markdown syntax instantly. See your formatting changes as you type with our real-time preview feature."
      }
    },
    {
      "@type": "Question",
      "name": "Is this markdown editor free to use?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our markdown live editor is completely free. Create tables, format text, add links, and more with our online markdown preview tool - no sign up required."
      }
    }
  ]
};

// Optimized page-specific metadata
const PAGE_METADATA: Record<string, PageMetadata> = {
  '/': {
    ...DEFAULT_METADATA,
    structuredData: {
      ...DEFAULT_METADATA.structuredData,
      "faq": FAQ_STRUCTURED_DATA
    }
  },
  
  '/cheatsheet': {
    title: 'Markdown Cheat Sheet - Complete Guide with Live Editor Preview',
    description: 'Interactive markdown cheat sheet with live preview. Test and learn markdown syntax instantly. Comprehensive guide for formatting text, creating tables, adding code blocks, and more.',
    keywords: 'markdown cheat sheet, markdown syntax, markdown guide, markdown live preview, markdown editor guide, markdown formatting guide, markdown tutorial with preview',
    type: 'article',
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Complete Markdown Cheat Sheet with Live Preview",
      "description": "Interactive markdown syntax guide with live preview",
      "articleSection": "Technical Documentation",
      "articleBody": "Complete guide to Markdown syntax with live preview examples"
    }
  },

  '/tools/table-generator': {
    title: 'Free Markdown Table Generator | Live Preview Editor',
    description: 'Create and format markdown tables easily with our free online generator. Live preview as you type, customize alignments, and export your tables instantly.',
    keywords: 'markdown table generator, markdown table maker, markdown table preview, create markdown table online, markdown table editor',
    type: 'website',
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebApplication",
      "name": "Markdown Table Generator",
      "description": "Create and format markdown tables with live preview",
      "applicationCategory": "DeveloperApplication",
      "featureList": [
        "Live table preview",
        "Custom alignments",
        "Easy column management",
        "Export options",
        "Copy to clipboard"
      ]
    }
  },

  '/tools/html-converter': {
    title: 'Markdown to HTML Converter | Live Preview Tool',
    description: 'Convert markdown to HTML with our free online converter. See live preview, copy formatted HTML, and export your content instantly.',
    keywords: 'markdown to html converter, markdown html preview, convert markdown online, markdown to html online, html preview',
    type: 'website',
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebApplication",
      "name": "Markdown to HTML Converter",
      "description": "Convert markdown to HTML with live preview"
    }
  }
};

export const Head: React.FC<HeadProps> = ({ 
  title: propTitle,
  description: propDescription,
  keywords: propKeywords
}) => {
  const location = useLocation();
  const canonicalUrl = `https://markdownlivepreview.dev${location.pathname}`;
  
  // Enhanced metadata getter with fallback logic
  const getMetadata = () => {
    // Check for exact path match
    if (PAGE_METADATA[location.pathname]) {
      return PAGE_METADATA[location.pathname];
    }
    
    // Check for tool pages with dynamic routes
    if (location.pathname.startsWith('/tools/')) {
      const toolPath = Object.keys(PAGE_METADATA).find(path => 
        path.startsWith('/tools/') && location.pathname.includes(path)
      );
      if (toolPath) return PAGE_METADATA[toolPath];
    }
    
    // Fallback to default metadata
    return DEFAULT_METADATA;
  };

  const metadata = getMetadata();

  // Enhanced breadcrumb generation with better labels
  const getBreadcrumbData = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const breadcrumbItems = pathSegments.map((segment, index) => {
      const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
      const name = segment
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return {
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@id": `https://markdownlivepreview.dev${path}`,
          "name": name
        }
      };
    });

    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "https://markdownlivepreview.dev/",
            "name": "Home"
          }
        },
        ...breadcrumbItems
      ]
    };
  };

  // Allow prop overrides while keeping defaults
  const finalTitle = propTitle || metadata.title;
  const finalDescription = propDescription || metadata.description;
  const finalKeywords = propKeywords || metadata.keywords;

  return (
    <Helmet>
      {/* Primary Meta Tags - Enhanced for SEO */}
      <html lang="en" />
      <title>{finalTitle}</title>
      <meta name="title" content={finalTitle} />
      <meta name="description" content={finalDescription} />
      <meta name="keywords" content={finalKeywords} />

      {/* OpenGraph Meta Tags - Enhanced for better social sharing */}
      <meta property="og:type" content={metadata.type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content="https://markdownlivepreview.dev/icons/icon-512x512.png" />
      <meta property="og:site_name" content="Markdown Live Editor" />

      {/* Twitter Card Meta Tags - Enhanced for better Twitter sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content="https://markdownlivepreview.dev/icons/icon-512x512.png" />

      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />

      {/* Structured Data - Enhanced with conditional rendering */}
      {metadata.structuredData && (
        <script type="application/ld+json">
          {JSON.stringify({
            ...metadata.structuredData,
            "url": canonicalUrl,
            "name": finalTitle,
            "description": finalDescription
          })}
        </script>
      )}

      {/* Breadcrumb Structured Data - Added for all non-home pages */}
      {location.pathname !== '/' && (
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumbData())}
        </script>
      )}

      {/* Additional Meta Tags for SEO and Mobile Optimization */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="author" content="DigitalPro" />
      <meta name="theme-color" content="#1e293b" />
      
      {/* PWA Meta Tags */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="apple-mobile-web-app-title" content="Markdown Live Editor" />
      <meta name="application-name" content="Markdown Live Editor" />

      {/* Preconnect to External Resources */}
      <link rel="preconnect" href="https://www.googletagmanager.com" />
    </Helmet>
  );
};

export default Head;