import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { EditorLayout } from '../App';

// Lazy load components
const Legal = lazy(() => import('../pages/legal/Legal'));
const Privacy = lazy(() => import('../pages/legal/Privacy'));
const GDPR = lazy(() => import('../pages/legal/GDPR'));
const CheatSheet = lazy(() => import('../pages/CheatSheet'));
const Examples = lazy(() => import('../pages/examples/Examples'));
const Tools = lazy(() => import('../pages/tools'));
const TableGenerator = lazy(() => import('../pages/tools/TableGenerator'));
const HtmlConverter = lazy(() => import('../pages/tools/HtmlConverter'));
const LinkGenerator = lazy(() => import('../pages/tools/LinkGenerator'));
const ListGenerator = lazy(() => import('../pages/tools/ListGenerator'));

// Loading component
const LoadingFallback = () => (
  <div className="h-full w-full flex items-center justify-center">
    <div className="animate-pulse text-slate-600">Loading...</div>
  </div>
);

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Main editor route */}
      <Route path="/" element={<EditorLayout />} />

      {/* Other routes */}
      <Route path="/cheatsheet" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <CheatSheet />
          </Suspense>
        </Layout>
      } />
      <Route path="/examples" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <Examples />
          </Suspense>
        </Layout>
      } />

      {/* Tools routes */}
      <Route path="/tools" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <Tools />
          </Suspense>
        </Layout>
      } />
      <Route path="/tools/table-generator" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <TableGenerator />
          </Suspense>
        </Layout>
      } />
      <Route path="/tools/html-converter" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <HtmlConverter />
          </Suspense>
        </Layout>
      } />
      <Route path="/tools/link-generator" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <LinkGenerator />
          </Suspense>
        </Layout>
      } />
      <Route path="/tools/list-generator" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <ListGenerator />
          </Suspense>
        </Layout>
      } />

      {/* Legal routes */}
      <Route path="/legal" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <Legal />
          </Suspense>
        </Layout>
      } />
      <Route path="/privacy" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <Privacy />
          </Suspense>
        </Layout>
      } />
      <Route path="/gdpr" element={
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <GDPR />
          </Suspense>
        </Layout>
      } />

      {/* 404 route - redirect to home */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;