import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Heart } from 'lucide-react';
import toast from 'react-hot-toast';

interface FooterProps {
  className?: string;
}

interface FooterLink {
  to: string;
  label: string;
  isExternal?: boolean;
}

const FOOTER_LINKS: FooterLink[] = [
  { to: '/legal', label: 'Legal' },
  { to: '/privacy', label: 'Privacy Policy' },
  { to: '/gdpr', label: 'GDPR' }
];

const EMAIL = 'hello@markdownlivepreview.dev';
const COMPANY_URL = 'https://digitalpro.dev';

const Footer: React.FC<FooterProps> = ({ className = '' }) => {
  const [isCopying, setIsCopying] = useState(false);

  const handleEmailClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (isCopying) return;

    try {
      setIsCopying(true);
      await navigator.clipboard.writeText(EMAIL);
      
      toast.success('Email copied to clipboard!', {
        duration: 2000,
        position: 'bottom-right',
        icon: '📋',
        style: {
          background: '#18181b',
          color: '#fff',
          padding: '12px',
          borderRadius: '6px',
        },
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
    } catch (error) {
      console.error('Failed to copy email:', error);
      toast.error('Failed to copy email', {
        duration: 3000,
        position: 'bottom-right',
        icon: '⚠️',
        style: {
          background: '#18181b',
          color: '#fff',
          padding: '12px',
          borderRadius: '6px',
        },
        ariaProps: {
          role: 'alert',
          'aria-live': 'assertive',
        },
      });
    } finally {
      setTimeout(() => setIsCopying(false), 1000);
    }
  }, [isCopying]);

  return (
    <footer className={`fixed bottom-0 left-0 right-0 bg-slate-900 text-slate-400 h-[30px] flex items-center z-10 ${className}`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          {/* Legal Links */}
          <div className="flex gap-2 sm:gap-4 text-xs">
            {FOOTER_LINKS.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="hover:text-white transition-colors px-2 py-1 rounded"
              >
                {link.label}
              </Link>
            ))}
            {/* Contact Link - Shows as full email on desktop, "Contact" on mobile */}
            <button
              onClick={handleEmailClick}
              className={`text-xs px-2 py-1 rounded transition-all hover:text-white
                ${isCopying ? 'cursor-wait' : ''}`}
              disabled={isCopying}
              aria-label={isCopying ? 'Copying email...' : 'Copy email to clipboard'}
            >
              <span className="hidden sm:inline">{EMAIL}</span>
              <span className="sm:hidden">Contact</span>
            </button>
          </div>

          {/* Copyright and Attribution */}
          <div className="absolute left-1/2 -translate-x-1/2 hidden sm:flex items-center gap-1.5 text-xs">
            <span className="text-slate-400">Made with</span>
            <Heart 
              size={12} 
              className="fill-pink-500 text-pink-500 animate-pulse" 
              aria-label="love"
            />
            <span className="text-slate-400">by</span>
            <a 
              href={COMPANY_URL}
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-slate-300 border-b border-slate-700 hover:text-white hover:border-slate-300 transition-colors"
              aria-label="Visit DigitalPro website"
            >
              DigitalPro
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;