import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Zap, Home, BookOpen, Code, Wrench, ChevronDown } from 'lucide-react';

interface HeaderProps {
  className?: string;
}

// Navigation items type definition
interface SubItem {
  path: string;
  label: string;
}

interface NavItem {
  path: string;
  icon: React.ElementType;
  label: string;
  ariaLabel: string;
  subItems?: SubItem[];
}

// Define navigation items
const NAV_ITEMS: NavItem[] = [
  {
    path: '/',
    icon: Home,
    label: 'Home',
    ariaLabel: 'Go to homepage'
  },
  {
    path: '/cheatsheet',
    icon: BookOpen,
    label: 'Cheat Sheet',
    ariaLabel: 'View Markdown Cheat Sheet'
  },
  {
    path: '/examples',
    icon: Code,
    label: 'Examples',
    ariaLabel: 'Browse Markdown Examples'
  },
  {
    path: '/tools',
    icon: Wrench,
    label: 'Tools',
    ariaLabel: 'Markdown Tools',
    subItems: [
      { path: '/tools', label: 'All Tools' },
      { path: '/tools/table-generator', label: 'Table Generator' },
      { path: '/tools/html-converter', label: 'HTML Converter' },
      { path: '/tools/link-generator', label: 'Link Generator' },
      { path: '/tools/list-generator', label: 'List Generator' }
    ]
  }
];

const Header: React.FC<HeaderProps> = ({ className = '' }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  // Handle dropdown toggle
  const toggleDropdown = useCallback((path: string) => {
    setOpenDropdown(current => current === path ? null : path);
  }, []);

  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest('.nav-dropdown')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close dropdown on navigation
  React.useEffect(() => {
    setOpenDropdown(null);
  }, [location.pathname]);

  // Memoized navigation item render function
  const renderNavItem = useCallback((item: NavItem) => {
    const isActive = location.pathname === item.path || 
      (item.subItems?.some(subItem => location.pathname === subItem.path) ?? false);
    const Icon = item.icon;
    const hasSubItems = item.subItems && item.subItems.length > 0;

    return (
      <div key={item.path} className="relative nav-dropdown">
        {hasSubItems ? (
          <button
            onClick={() => toggleDropdown(item.path)}
            className={`p-2 rounded-md transition-colors duration-200 ${
              isActive ? 'text-white bg-slate-800' : 'text-slate-400 hover:text-white hover:bg-slate-800'
            }`}
            aria-label={item.ariaLabel}
            aria-expanded={openDropdown === item.path}
            aria-haspopup="true"
          >
            <div className="flex items-center gap-1">
              <Icon 
                size={24} 
                aria-hidden="true"
                className="transition-transform duration-200 hover:scale-110"
              />
              <ChevronDown 
                size={16} 
                className={`transition-transform duration-200 ${
                  openDropdown === item.path ? 'rotate-180' : ''
                }`}
              />
            </div>
          </button>
        ) : (
          <Link
            to={item.path}
            className={`p-2 rounded-md inline-flex items-center transition-colors duration-200 ${
              isActive ? 'text-white bg-slate-800' : 'text-slate-400 hover:text-white hover:bg-slate-800'
            }`}
            aria-label={item.ariaLabel}
            aria-current={isActive ? 'page' : undefined}
            title={item.label}
          >
            <Icon 
              size={24} 
              aria-hidden="true"
              className="transition-transform duration-200 hover:scale-110"
            />
          </Link>
        )}

        {/* Dropdown Menu */}
        {hasSubItems && item.subItems && openDropdown === item.path && (
          <div className="absolute right-0 top-full mt-1 w-48 py-1 bg-slate-800 rounded-lg shadow-lg z-50">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.path}
                to={subItem.path}
                className={`block px-4 py-2 text-sm transition-colors ${
                  location.pathname === subItem.path
                    ? 'bg-slate-700 text-white'
                    : 'text-slate-300 hover:bg-slate-700 hover:text-white'
                }`}
              >
                {subItem.label}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  }, [location.pathname, openDropdown, toggleDropdown]);

  // Handle logo click - with error boundary
  const handleLogoClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      if (location.pathname === '/') {
        e.preventDefault();
      }
    } catch (error) {
      console.error('Error handling logo click:', error);
    }
  }, [location.pathname]);

  return (
    <header 
      className={`bg-slate-900 h-[40px] text-white flex items-center z-10 ${className}`}
      role="banner"
    >
      <nav 
        className="container mx-auto px-4 flex justify-between items-center"
        role="navigation"
        aria-label="Main navigation"
      >
        {/* Logo and Title */}
        <Link 
          to="/"
          className="flex items-center gap-2 hover:text-slate-200 transition-colors"
          onClick={handleLogoClick}
          aria-label="Markdown Live Preview - Go to homepage"
        >
          <Zap 
            size={28} 
            className="text-indigo-500 transition-transform hover:scale-110" 
            aria-hidden="true"
          />
          <h1 className="text-xl font-bold tracking-tight hidden sm:block">
            Markdown Live Preview
          </h1>
        </Link>

        {/* Navigation Icons */}
        <div 
          className="flex items-center gap-2"
          role="navigation"
          aria-label="Secondary navigation"
        >
          {NAV_ITEMS.map(renderNavItem)}
        </div>
      </nav>
    </header>
  );
};

export default Header;