import { Copy, Download, Code, Loader2 } from 'lucide-react';

interface PreviewToolbarProps {
  onCopyText: () => void;
  onCopyHtml: () => void;
  onDownloadText: () => void;
  copyTextInProgress: boolean;
  copyHtmlInProgress: boolean;
  hasContent: boolean;
}

export function PreviewToolbar({
  onCopyText,
  onCopyHtml,
  onDownloadText,
  copyTextInProgress,
  copyHtmlInProgress,
  hasContent
}: PreviewToolbarProps) {
  return (
    <div className="flex flex-col bg-slate-50 border-b">
      <div className="px-3 py-2 overflow-x-auto">
        <div className="flex items-center justify-between space-x-2 min-w-max">
          <div className="text-sm font-medium text-slate-600">
            Preview
          </div>
          
          <div className="flex items-center space-x-2">
            <button
              onClick={onCopyText}
              className={`p-1.5 rounded-md transition-colors border border-slate-200 ${
                copyTextInProgress 
                  ? 'bg-slate-100 text-slate-400 cursor-wait' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100'
              }`}
              disabled={copyTextInProgress || !hasContent}
              title="Copy rendered text"
              aria-label="Copy preview content as text"
            >
              {copyTextInProgress ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </button>

            <button
              onClick={onCopyHtml}
              className={`p-1.5 rounded-md transition-colors border border-slate-200 ${
                copyHtmlInProgress 
                  ? 'bg-slate-100 text-slate-400 cursor-wait' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100'
              }`}
              disabled={copyHtmlInProgress || !hasContent}
              title="Copy as HTML"
              aria-label="Copy preview content as HTML"
            >
              {copyHtmlInProgress ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Code className="h-4 w-4" />
              )}
            </button>

            <button
              onClick={onDownloadText}
              className="p-1.5 rounded-md transition-colors border border-slate-200 text-slate-500 hover:text-slate-700 hover:bg-slate-100 disabled:opacity-50 disabled:hover:bg-transparent"
              title="Download as text file"
              aria-label="Download preview content as text"
              disabled={!hasContent}
            >
              <Download className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}